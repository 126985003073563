<template>
  <div>
    <grid :representService="representService" />
  </div>
</template>

<script>
import grid from "@/components/grid.vue";
import RepresentService from "@/services/represent-service";

export default {
  name: "list-view",
  components:{
    grid
  },
  data(vm) {
    return {
      representService: new RepresentService(vm.$route.params.type.toLowerCase())
    }
  },
  watch: {
    $route(to) {
      if (to.params.type)
        this.representService = new RepresentService(to.params.type.toLowerCase());
      else this.representService = null;
    }
  },
};
</script>