<template>
  <div class="chart">
    <DoughnutChart v-if="chartdata" v-bind="doughnutChartProps" :options="options"/>
  </div>
</template>

<script>
import {Chart, registerables} from 'chart.js';
import {DoughnutChart, useDoughnutChart} from 'vue-chart-3';
import {ref, computed, defineComponent, onMounted} from 'vue';
import {shuffle} from 'lodash';
import api from '@/api'

Chart.register(...registerables);

export default defineComponent({
  name: 'App',
  components: {
    DoughnutChart,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          year: null,
          month: null,
          lab: null,
        }
      }
    }
  },
  data() {
    return {
      legend: null,
      yeardata: null,
      monthdata: null,
      labdata: null,
      chartdata: true,
      titleinfo: null,
      labinfo: 'Распределение по лабораториям',
      ugpsinfo: 'Распределение по УГПС',
    }
  },
  watch: {
    filter: {
      handler(value) {
        if(value != null){
          this.loadChartData();
        }
      },
    }
  },
  methods: {
    async loadChartData() {
      if(this.filter?.year == null || this.filter?.month == null || this.filter?.lab == null )
        return;

      this.chartdata = false;
      if(this.filter.lab === 0)
      {
        this.charttitle = this.labinfo;
        this.chartData = await api.labchart.getchartAllData(this.filter);
        this.chartdata = true;
      }
      else
      {
        this.charttitle = this.ugpsinfo;
        this.chartData = await api.labchart.getchartData(this.filter);
        this.chartdata = true;
      }
    },
  },
  async mounted() {
    await this.loadChartData();
  },
  setup(props) {
    
    const charttitle = ref('Распределение по лабораториям');
    const chartData = ref(null);

    const {doughnutChartProps, doughnutChartRef} = useDoughnutChart({
      chartData,
    });

    const options = ref({
      responsive: true,
      rotation: 90,
      elements: {
        point: {
          backgroundColor: 'transparent'
        },
      },
      plugins: {
        tooltip: {
          enabled: false
        },
        decimation: {
          enabled: false
        },
        datalabels: {
          color: '#fff',
            // function(context) {
            //   let value = context.dataset.data[context.dataIndex];
            //   return value < 4 ? '#2B2D5B' : '#fff';
            // },
          font: {
            size: 16,
            weight: 700,
            family: '"Segoe UI"'
          },
          formatter: function(value, context) {
            return Math.round(value) + '%';
          },
          // align: function(context) {
          //   let value = context.dataset.data[context.dataIndex];
          //   return value < 4 ? 'right' : 'center';
          // },
          offset: 40
        },
        htmlLegend: {containerID: 'labChartLegend'},
        legend: {
          onClick: () =>{
            return false
          },
          display: true,
          position: 'right',
          align: 'start',
          fullSize: false,
          maxWidth: 510,
          maxHeight: 1000,
          labels: {
            usePointStyle: true,
            boxWidth: 25,
            boxPadding: 25,
            padding: 16,
            font: {
              size: 16,
              family: '"Segoe UI"'
            },
          },
        },
        title: {
          display: true,
          text: charttitle,
          color: '#2B2D5B',
          fullSize: true,
          padding: {
            bottom: 25,
            top: 15,
          },
          font: {
            size: 18,
            family: '"Segoe UI"',
            weight: 600
          }
        },
      },
    });

    return {
      doughnutChartProps,
      doughnutChartRef,
      options,
      chartData,
      charttitle,
    };
  },
});
</script>
<style>
</style>