<template>
  <div class="barStat">
    <div>
      <a-space>
        <a-select
          v-model:value="year"
          style="width: 120px"
          :options="yearFilterData"
          @change="handleChange(year, lab)"
        >
        </a-select>
        <!-- <a-select
          v-model:value="lab"
          style="width: 200px"
          :options="labFilterData"
          @change="handleChange(year, lab)"
        >
        </a-select> -->
      </a-space>
    </div>
    <div class="chart" >
      <BarChart v-bind="barChartProps" :options="options"/>
    </div>
  </div>
</template>

<script>
import {Chart, registerables} from 'chart.js';
import {BarChart, useBarChart} from 'vue-chart-3';
import {defineComponent, ref} from 'vue';
import api from '@/api'

Chart.register(...registerables);

export default defineComponent({
  name: 'BarStat',
  components: {
    BarChart,
  },
    data() {
    return {
      legend: null,
      yeardata: null,
      monthdata: null,
      labdata: null,
    }
  },

  async mounted() {
    this.yearFilterData = await api.dataslicestatistic.getFilters();
    this.year = this.yearFilterData[0].value
    this.chartData = await api.barchart.getData({year: this.year, lab: this.lab});
  },

  setup() {

    const year = ref(null);
    const yearData = ref(null);

    const labData = ref(null);

    const chartData = ref(null);

    const {barChartProps, barChartRef} = useBarChart({
      chartData,
    });

    const options = ref({
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            color: '#2B2D5B',
            font: {
              size: 18,
              weight: 400,
              family: '"Segoe UI"'
            },
          }
        },
        y: {
          display: false,
        }
      },
      plugins: {
        tooltip: {
          enabled: false
        },
        datalabels: {
          align: 'end',
          anchor: 'end',
          color: '#2B2D5B',
          font: {
            size: 18,
            weight: 400,
            family: '"Segoe UI"'
          },
        },
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Наполняемость Реестра, число согласованных ПООП',
          color: '#2B2D5B',
          padding: {
            bottom: 65,
            top: 15
          },
          font: {
            size: 18,
            family: '"Segoe UI"',
            weight: 600
          }
        },
      },
    });

    const yearFilterData = ref(null);

    const labFilterData = ref([
      {
        value: 0,
        label: 'Все лаборатории'
      },
    ]);

    const handleChange = async (year, lab) => {
      chartData.value = await api.barchart.getData({year: year, lab: lab});
    };
    // onBeforeMount(async () => {
    //   yearFilterData.value = await api.barchart.getYears();

    //   var filter = {year: '2021', lab: 'all'};
    //   labFilterData.value = await api.barchart.getLabs(filter);
    // });

    return {year, lab: ref(0), yearData, labData, barChartProps, barChartRef, options, yearFilterData, labFilterData, chartData, handleChange};
  },
});
</script>

<style lang="scss">
.barStat {
  margin-bottom: 1.5rem;

  & > .chart {
    padding: 1.5rem;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    margin-top: 1.5rem;
  }
}
</style>