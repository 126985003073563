<template>
  <div class="diagramStat">
    <div>
      <a-space>
        <a-select
          v-model:value="yearSelect"
          style="width: 120px"
          :options="yearFilterData"
          @change="yearChange(yearSelect)"
        />
        <a-select
          v-model:value="monthSelect"
          style="width: 120px"
          :options="monthFilterData"
          @change="monthChange(monthSelect)"
        />
        <a-select
          v-model:value="labSelect"
          style="width: 600px"       
          :options="labFilterData"
          @change="labChange(labSelect)"
        />
      </a-space>
    </div>
    <a-row :gutter="24">
      <a-col :span="24" class="gutter-row">
        <lab-chart
          :filter="filter"
        />
      </a-col>
      <a-col :span="12" class="gutter-row">
        <status-chart class="gutter-box"
          :filter="filter"
        />
      </a-col>
      <a-col :span="12" class="gutter-row">
        <level-chart class="gutter-box"
          :filter="filter"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {ref, onBeforeMount, computed, defineComponent} from 'vue';

import LabChart from './diagrams/labChart'
import StatusChart from './diagrams/statusChart'
import LevelChart from './diagrams/levelChart'
import api from '@/api'
import moment from 'moment'

export default defineComponent({
  name: 'DiagramStat',
  components: {
    LabChart,
    StatusChart,
    LevelChart
  },
  setup() {
    const monthSelect = ref(null);
    const filter = ref(null);
    const yearSelect = ref(null);
    const labSelect = ref(null);
    const monthFilterData = ref(null);
    const yearFilterData = ref(null);
    const labFilterData = ref(null);
    const filters = ref(null);

    const yearChange = async (yearValue) => {
      let yearFilter = yearFilterData.value.filter(f => f.value === yearValue)[0];
      
      monthFilterData.value = yearFilter.monthFilters;
      labFilterData.value = monthFilterData.value[0].labFilters;

      yearSelect.value = yearValue;
      monthSelect.value = monthFilterData.value[0].value;
      labSelect.value = labFilterData.value[0].value;
      filter.value = {
        year: yearSelect.value,
        month: monthSelect.value,
        lab: labSelect.value,
      }
    };

    const monthChange = async (monthValue) => {
      let monthFilter = monthFilterData.value.filter(f => f.value === monthValue)[0];
      
      labFilterData.value = monthFilter.labFilters;
      monthSelect.value = monthValue;
      labSelect.value = labFilterData.value[0].value;
      filter.value = {
        year: yearSelect.value,
        month: monthSelect.value,
        lab: labSelect.value,
      }
    };

    const labChange = async (value) => {
      labSelect.value = value;
      filter.value = {
        year: yearSelect.value,
        month: monthSelect.value,
        lab: labSelect.value,
      }
    };

    onBeforeMount(async () => {
      filters.value = await api.dataslicestatistic.getFilters();

      yearFilterData.value = filters.value;
      monthFilterData.value = yearFilterData.value[0].monthFilters;
      labFilterData.value = monthFilterData.value[0].labFilters;
      yearSelect.value = yearFilterData.value[0].value;
      monthSelect.value = monthFilterData.value[0].value;
      labSelect.value = labFilterData.value[0].value;
      filter.value = {
        year: yearSelect.value,
        month: monthSelect.value,
        lab: labSelect.value,
      }
    });

    return {
      monthSelect,
      filter,
      yearSelect,
      labSelect,
      monthFilterData,
      yearFilterData,
      labFilterData,
      yearChange,
      monthChange,
      labChange,
    };
  },
});
</script>

<style lang="scss">
.diagramStat {
  margin-bottom: 1.5rem;

  & .chart {
    padding: 1.5rem;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    margin-top: 1.5rem;
  }
}
</style>