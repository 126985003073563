<template>
  <div>
    <a-page-header
      title="Новости и анонсы"
    />
    <div v-if="heronews">
      <article-hero :article="heronews"/>
    </div>
    <div v-if="news" class="widget-area">
      <div class="widget-block">
        <div class="widget listNews" v-if="news">
          <article-card
            v-for="article in news"
            :key="article.id"
            :article="article"
            :showCover="true"
          />
          <div class="allnews-link">
          <router-link to="/allNews">Все новости</router-link>
          </div>
        </div>
      </div>
      <div class="widget-block">
        <div class="widget-title">
          <h2>Новое в реестре</h2>
          <router-link to="/reestrNews">
            <span>История обновлений</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
              <path fill="#2B2D5B"
                    d="M14.825 3.127l-.97 1.097 8.533 7.544H0v1.464h22.388l-8.533 7.544.97 1.097L25 12.88v-.758L14.825 3.127z"/>
            </svg>
          </router-link>
        </div>
        <div class="widget reestrNews" v-if="registryNews">
          <articleRegistryCard
            v-for="article in registryNews"
            :key="article.id"
            :article="article"
          />
        </div>
      </div>
    </div>
    <div class="widget stats">
      <h2>Статистика платформы</h2>
      <div class="stats-meta">
        <div>Последнее обновление данных: <span>{{moment(lastDataSlice?.date).format('DD.MM.YYYY')}}</span></div>
        <div>Всего ПООП в Реестре: <span>{{lastDataSlice?.sampleProgramQuantity}}</span></div>
      </div>
    </div>
    <div>
      <diagram-stat />
    </div>
    <div>
      <bar-stat />
    </div>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue'
import articleHero from '@/components/sp-articles/articleHero'
import articleCard from '@/components/sp-articles/articleCard'
import articleRegistryCard from '@/components/sp-articles/articleRegistryCard'
import diagramStat from '@/components/sp-charts/diagramStat'
import barStat from '@/components/sp-charts/barStat'
import api from '@/api'
import moment from 'moment'

export default {
  name: "Homepage",
  components: {articleHero, articleCard, diagramStat, barStat, articleRegistryCard},
  setup() {
    const news = ref(null);
    const heronews = ref(null);
    const registryNews = ref(null);
    const lastDataSlice = ref(null);

    onMounted(async () => {
      lastDataSlice.value = await api.dataslicestatistic.getLast();
      news.value = (await api.news.getNews()).data;
      news.value = news.value.slice(0,2);
      heronews.value = await api.news.getHeroNews();
      registryNews.value = (await api.registrynews.getRegistryNews()).data;
      registryNews.value = registryNews.value.slice(0,3);
    });

    return {
      news,
      heronews,
      registryNews,
      lastDataSlice,
      moment,
    }
  }
}
</script>

<style lang="scss">
.ant-page-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;

  & .ant-page-header-heading-title {
    font-size: 2rem;
    font-weight: 700;
  }
}

.listNews {
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
}

.widget-area {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2rem;
  margin-bottom: 6rem;
  

  & .widget-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    & h2 {
      font-weight: 600;
      font-size: 30px;
      color: #2B2D5B;
    }

    & a {
      display: flex;
      align-items: center;
      font-size: 18px;

      & span {
        transition: all 0.3s;
        border-bottom: 1px solid transparent;
      }

      &:hover {
        color: inherit;

        & span {
          border-color: #cccccc;
        }
      }

      & svg {
        margin-left: 1rem;
      }
    }
  }
}

.widget.listNews {

  & .articleCard {
    & .cover {
      height: 100%;
      width: 35% !important;
    }

    & .main-block {
      width: 65% !important;
    }
  }
}

.widget.reestrNews {
  margin-bottom: 2rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.widget.stats {
  & h2 {
    font-weight: 600;
    font-size: 30px;
    color: #2B2D5B;
    margin-bottom: 1rem;
  }
  & .stats-meta {
    margin-bottom: 1.5rem;
    font-size: 18px;
  }
}
</style>