<template>
  <div class="singlePage">
    <div class="mainColumn">
    <div class="meta">
      <h1>
        Контакты
      </h1>
    </div>
    <div class="content" v-html="content">
    </div>
  </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      content: ""
    };
  },
  async created() {
    this.content = await api.contacts.getPageInfo();
  }
}
</script>