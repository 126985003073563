<template>
  <div class="chart">
    <PieChart v-if="chartdata" v-bind="pieChartProps" :options="options"/>
  </div>
</template>

<script>
import {Chart, registerables} from 'chart.js';
import {PieChart, usePieChart} from 'vue-chart-3';
import {ref, computed, defineComponent} from 'vue';
import {shuffle} from 'lodash';
import api from '@/api'

Chart.register(...registerables);

export default defineComponent({
  name: 'LevelChart',
  components: {
    PieChart,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          year: null,
          month: null,
          lab: null,
        }
      }
    }
  },
  data() {
    return {
      legend: null,
      yeardata: null,
      monthdata: null,
      labdata: null,
      chartdata: true,
    }
  },
  watch: {
    filter: {
      handler(value) {
        if(value != null){
          this.loadChartData();
        }
      },
    }
  },
  methods: {
    async loadChartData() {
      if(this.filter?.year == null || this.filter?.month == null || this.filter?.lab == null )
        return;

      this.chartdata = false;

      this.chartData = await api.labchart.getlevelData(this.filter);

      this.chartdata = true;      

    },
  },

  async mounted() {
    await this.loadChartData();
  },

  setup() {

    const chartData = ref(null);

    // const data = ref([80, 20]);

    // const chartData = computed(() => ({
    //   labels: [
    //     'Среднее звено',
    //     'Квалифицированные рабочие'
    //   ],
    //   datasets: [
    //     {
    //       data: data.value,
    //       borderSkipped: true,
    //       borderWidth: 0,
    //       backgroundColor: ['#FE8202', '#ACDA03'],
    //     },
    //   ],
    // }));

    const {pieChartProps, pieChartRef} = usePieChart({
      chartData,
    });

    const options = ref({
      responsive: true,
      rotation: 90,
      elements: {
        point: {
          backgroundColor: 'transparent'
        }
      },
      plugins: {
        tooltip: {
          enabled: false
        },
        decimation: {
          enabled: false
        },
        datalabels: {
          color:
            function(context) {
              let value = context.dataset.data[context.dataIndex];
              return value < 4 ? '#2B2D5B' : '#fff';
            },
          font: {
            size: 16,
            weight: 700,
            family: '"Segoe UI"'
          },
          formatter: function(value, context) {
            return Math.round(value) + '%';
          },
          align: function(context) {
            let value = context.dataset.data[context.dataIndex];
            return value < 4 ? 'top' : 'center';
          },
          offset: 40
        },
        legend: {
          onClick: () =>{
            return false
          },
          position: 'right',
          align: 'center',
          fullSize: false,
          maxWidth: 510,
          // title: {
          //   display: true,
          //   text: 'Распределение по уровням',
          //   position: 'start',
          //   color: '#2B2D5B',
          //   fullSize: true,
          //   padding: {
          //     bottom: 5,
          //     right: 100,
          //   },
          //   font: {
          //     size: 18,
          //     family: '"Segoe UI"',
          //     weight: 600
          //   }
          // },
          labels: {
            usePointStyle: true,
            boxWidth: 25,
            boxPadding: 25,
            padding: 16,
            font: {
              size: 16,
              family: '"Segoe UI"'
            }
          }
        },
        title: {
          display: true,
          text: 'Распределение по уровням программы',
          color: '#2B2D5B',
          fullSize: true,
          padding: {
            bottom: 25,
            top: 15
          },
          font: {
            size: 18,
            family: '"Segoe UI"',
            weight: 600
          }
        },
      },
    });

    return {
      pieChartProps,
      pieChartRef,
      options,
      chartData,
    };
  },
});
</script>